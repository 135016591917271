export const environment = {
  production: false,
  auth0:{
    domain: 'login.otrleasing.com',
    clientId: 'dXOkc1BL9K6n8bYfqvH3PeWzaGgfRUHI',
    audience: 'https://otrleasing-api',
    redirect_uri: 'http://localhost:4200/login',
  },
  lease_path: {
    base_uri: 'https://stage.api.otrleasing.com/api/',
    key: 'b3RyTGVzaW5nU2VydmljZVVzZXI6VyEzM1NlcnZpY2VAcHA=',
    env: 'stage'
  },
  gmKey: 'AIzaSyAeW6fH15eawB1RsImAvd7PsG9XOPXxy-s',
};
